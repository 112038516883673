<template>
  <b-modal
    :id="`food-modal-${id}`"
    :ref="`food-modal-${id}`"
    :title="getTitleModal"
    hide-footer
    centered
    size="lg"
    @hidden="resetModal"
  >
    <b-overlay :show="loading">
      <div>
        <form-food
          :data="foodDetail"
          :food-id="foodId"
          @cancel="resetModal"
          @submit="submit"
        />
      </div>
    </b-overlay>
  </b-modal>
</template>
<script>
import { createNamespacedHelpers } from 'vuex';
import {
  SET_MODAL,
  SET_BODY_FOOD_TEMPLATE,
  SET_SUBMIT_FOOD,
} from '@/core/services/store/menu.module';
const { mapMutations, mapState } = createNamespacedHelpers('menu');
export default {
  name: 'ModalFood',
  components: {
    'form-food': () => import('./FormFood.vue'),
  },
  props: {
    type: {
      type: String,
      default: 'create',
    },
    foodId: {
      type: String,
      default: null,
    },
    id: {
      type: [String, Number],
      default: null,
    },
  },
  data() {
    return {
      loading: false,
      foodDetail: null,
    };
  },
  computed: {
    ...mapState(['menuDetail', 'submitFoodSuccess']),
    getTitleModal() {
      return this.type === 'create' ? 'Chọn món ăn' : 'Chỉnh sửa món ăn';
    },
  },
  watch: {
    foodId(val) {
      if (!val || this.type === 'create') return;
      this.getFoodDetail();
    },
  },
  created() {
    if (this.foodId && this.type !== 'create') {
      this.getFoodDetail();
    }
  },
  methods: {
    ...mapMutations({
      SET_MODAL,
      SET_BODY_FOOD_TEMPLATE,
      SET_SUBMIT_FOOD,
    }),
    async getFoodDetail() {
      try {
        this.loading = true;
        const { data, meta } = await this.$api.get(
          `/FoodTemplate/${this.foodId}`,
        );
        if (meta.success) {
          this.foodDetail = data;
        }
      } catch (error) {
        this.$toastr.e({
          title: 'Lỗi !',
          msg: error.message,
        });
      } finally {
        this.loading = false;
      }
    },
    resetModal() {
      this.$bvModal.hide(`food-modal-${this.id}`);
      this.SET_BODY_FOOD_TEMPLATE(null);
      this.foodDetail = null;
      this.$emit('hide');
    },
    submit(params) {
      if (this.type === 'create') {
        params['foodMenuCode'] = this.menuDetail.code;
        this.createFood(params);
      } else {
        this.updateFood(params);
      }
    },
    async updateFood(params) {
      this.loading = true;
      try {
        const { data } = await this.$api.put('/FoodTemplate/Input', params);
        if ([200, 201].includes(data.statusCode)) {
          this.SET_SUBMIT_FOOD(!this.submitFoodSuccess);
          return this.resetModal();
        }
        return this.$toastr.e({
          title: 'Lỗi !',
          msg: data.message,
        });
      } catch (error) {
        this.$toastr.e({
          title: 'Lỗi !',
          msg: error.message,
        });
      } finally {
        this.loading = false;
      }
    },
    async createFood(params) {
      this.loading = true;
      try {
        const { data } = await this.$api.post('/FoodTemplate', params);
        if ([200, 201].includes(data.statusCode)) {
          this.SET_SUBMIT_FOOD(!this.submitFoodSuccess);
          return this.resetModal();
        }
        return this.$toastr.e({
          title: 'Lỗi !',
          msg: data.message,
        });
      } catch (error) {
        this.$toastr.e({
          title: 'Lỗi !',
          msg: error.message,
        });
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
<style lang=""></style>
